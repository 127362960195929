import React from 'react';
import LogoNextDayPersonalLoans from '../images/logos/logo-next-day-personal-loan.png';
import LogoLendingForBadCredit from '../images/logos/logo-lending-for-bad-credit.svg';

export default function debtConslidationOffers() {
    return {
        offers: [{
            logo: LogoNextDayPersonalLoans,
            company: 'Next Day Personal Loans',
            link: 'https://www.ac934intrk.com/3J67C/363TCP/?sub3=60455',
            rating: 5,
            details: [{
                header: 'Loan Amount',
                content: 'Up to $40,000',
            }, {
                header: 'Interest Rate (APR)',
                content: 'Varies',
            }, {
                header: 'Loan Terms',
                content: 'Varies',
            }, {
                header: 'Loan Example',
                content: 'Show representative example',
                additional: '$5,000 loan for 24 months = $5,318.47 paid back (6% APR% effective APR)'
            }],
            mainValueProp: 'Personal loans from $1,000 to $40,000',
            benefits: [
                {
                    item: (<>A nation-wide marketplace of lenders and lending partners</>),
                },
                {
                    item: (<>Funds deposited directly to your bank as soon as the next business day</>),
                },
                {
                    item: (<>All credit types considered</>),
                },
                {
                    item: (<>Click here for official site, terms, and details</>),
                    link: true
                }
            ],
            review: (
                <>
                    <p>Next Day Personal Loans has a nation-wide marketplace of lending partners. They currently offer loans up to $40,000 and they have a large number of lenders who specialize in credit card consolidation, debt consolidation loans, and debt settlement. They also work with lenders who provide home improvement loans, auto loans, and loans for continuing education. Many of these lenders work with less than perfect credit scores and can get funds quickly to qualified borrowers.</p>
                    <p>The loans are typically paid back in automated monthly or bi-monthly installments over an extended period of time. Next Day Personal Loans is not a lender, so repayment terms may vary, including origination fees and interest rates. It’s essential that you review all of these terms and conditions before entering into a loan agreement.</p>
                </>
            ),
        },{
            logo: LogoLendingForBadCredit,
            company: 'Lending For Bad Credit',
            link: 'https://www.ac934intrk.com/3J67C/69KHPZ/?sub3=61446',
            rating: 5,
            details: [{
                header: 'Loan Amount',
                content: '$5,000 - $100,000',
            }, {
                header: 'Interest Rate (APR)',
                content: 'Varies',
            }, {
                header: 'Loan Terms',
                content: 'Varies',
            }, {
                header: 'Loan Example',
                content: 'Show representative example',
                additional: '$5,000 loan for 24 months = $5,318.47 paid back (6% APR% effective APR)'
            }],
            mainValueProp: 'A fast and easy way to find a loan!',
            benefits: [
                {
                    item: (<>Loans from $100-$40,000 available</>),
                },
                {
                    item: (<>Marketplace of lenders ready to help you!</>),
                },
                {
                    item: (<>Request a loan in under 2 minutes!</>),
                },
                {
                    item: (<>Click here for official site, terms, and details</>),
                    link: true
                }
            ],
            review: (
                <>
                    <p>Lending for Bad Credit has a nation-wide marketplace of lending partners. They currently offer loans up to $40,000 and they have a large number of lenders who specialize in credit card consolidation, debt consolidation loans, and debt settlement. They also work with lenders who provide home improvement loans, auto loans, and loans for continuing education. Many of these lenders work with less than perfect credit scores and can get funds quickly to qualified borrowers.</p>
                    <p>The loans are typically paid back in automated monthly or bi-monthly installments over an extended period of time. Lending for Bad Credit is not a lender, so repayment terms may vary, including origination fees and interest rates. It’s essential that you review all of these terms and conditions before entering into a loan agreement.</p>
                </>
            ),
        }]
    }
}